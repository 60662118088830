import api from "@/base/utils/request";

// 获取品牌详情
export const spiritList = data => {
  return api({
    url: "/admin/cyc/Spirit/index",
    method: "post",
    data
  });
};

// 更新排序
export const updateSort = data => {
  return api({
    url: "/admin/cyc/Spirit/updateSort",
    method: "post",
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/cyc/Spirit/updateShow",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/cyc/Spirit/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc/Spirit/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/cyc/Spirit/putBack",
    method: "post",
    data
  });
};